<template>
  <div class="customersRecommend">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
      <el-breadcrumb-item>客源智推</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="recommended-box fl-box">
      <div class="company">
        <!-- <div class="header">
          <div class="name">入驻企业：上海艾瑞斯电子有限公司</div>
          <div class="fl-box">
            <div class="info">
              <div class="title">主要产品</div>
              <div class="text">
                供应UPS不间断<br />
                电源、蓄电池
              </div>
            </div>
            <div class="info">
              <div class="title">园区信息</div>
              <div class="lable">园内地址：<span class="text">3号楼1010</span></div>
              <div class="lable">入驻时间：<span class="text">2020-10-10</span></div>
            </div>
            <div class="info3 info">
              <div class="title">公司信息</div>
              <div class="fl-box-b">
                <div>
                  <div class="lable">成立时间：<span class="text">2020-10-10</span></div>
                  <div class="lable">注册资本：<span class="text">300万</span></div>
                  <div class="lable">营业执照：<span class="text">WKDJAKEJDKAJE</span></div>
                </div>
                <div>
                  <div class="lable">认证信息：<span class="attest">已认证</span></div>
                  <div class="lable">招商负责人：<span class="text">王富贵</span></div>
                  <div class="lable">联系电话：<span class="text">188****1000</span></div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="p020">
          <div class="select-box">
            客源
            <el-select v-model="value" style="margin-left: 12px" @change="change">
              <el-option label="温州某建材公司" value="温州某建材公司">温州某建材公司</el-option>
              <el-option label="温州某房地产公司" value="温州某房地产公司">温州某房地产公司</el-option>
              <el-option label="某钢材贩卖点" value="某钢材贩卖点">某钢材贩卖点</el-option>
              <el-option label="铁路建设公司" value="铁路建设公司">铁路建设公司</el-option>
            </el-select>
          </div>
          <div id="container"></div>
        </div>
      </div>
      <div class="customersource" v-loading="show">
        <div class="header">
          <div class="name">
            客源信息：<br />
            <span>浙江省温州市某某某某建材公司</span>
          </div>
          <div class="fl-box">
            <div class="tag">曾经客户</div>
            <div class="tag">主营产品</div>
            <div class="tag">价格满足</div>
            <div class="tag">产地满足</div>
          </div>
          <div class="fl-box" style="margin-bottom: 20px">
            <el-button
              @click="ignoreLog = true"
              type="primary"
              style=" margin-right: 12px; height: 32px; line-height: 32px; padding: 0 20px"
              >忽略客源</el-button
            >
            <el-button @click="phoneLog = true" type="danger" style="background-color: #e82442; height: 32px; line-height: 32px; padding: 0 20px">
              <img src="../../assets/img/icon_lx.png" alt="" class="icon_lx" />联系</el-button
            >
          </div>
        </div>
        <div style="height: 344px">
          <!-- <div style="height: 645px"> -->
          <el-scrollbar style="height: 100%">
            <div class="p020">
              <div class="procurement">
                <div class="title">采购需求</div>
                <div class="text">
                  <div class="lable">推荐指数：</div>
                  <span class="index">9.5</span>
                </div>
                <div class="text">
                  <div class="lable">所在地区：</div>
                  <span>中国</span>
                </div>
                <div class="text">
                  <div class="lable">需求发布时间：</div>
                  <span>2020-10-10</span>
                </div>
                <div class="text">
                  <div class="lable">发票要求：</div>
                  <span>需要发票 / 税率 13%</span>
                </div>
                <div class="text">
                  <div class="lable">已报价企业：</div>
                  <span>5 家</span>
                </div>
              </div>
            </div>
            <el-table :data="table" style="margin: 0 12px; width: 324px">
              <el-table-column label="序号" prop="num" align="center" width="40"></el-table-column>
              <el-table-column label="产品名称" prop="name" align="center" width="60"></el-table-column>
              <el-table-column label="单位" prop="unit" align="center" width="40"></el-table-column>
              <el-table-column label="数量" prop="number" align="center" width="40"></el-table-column>
              <el-table-column label="备注信息" prop="remark" align="center" width="72"></el-table-column>
              <el-table-column label="附件" align="center" width="72">
                <template slot-scope="scope">
                  <div v-if="scope.row.attachment" style="padding: 0 6px">
                    <a :href="scope.row.attachment">{{ scope.row.text }}</a>
                  </div>
                  <span v-else>/</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="p020">
              <div class="dynamic">
                <div class="title">企业动态</div>
                <div class="text">
                  2020年10月，发布某某某招标信息。<br />
                  2020年12月，与某某企业达成战略合作协议<br />
                  2020年12月，成为中国铁路建设的唯一钢材供应商<br />
                </div>
              </div>
              <div class="dynamic">
                <div class="title">企业业务</div>
                <div class="text">主营各种钢材，板材等建材。</div>
              </div>
              <div class="procurement">
                <div class="title">企业信息</div>
                <div class="text">
                  <div class="lable">成立时间：</div>
                  <span>2019-10</span>
                </div>
                <div class="text">
                  <div class="lable">注册资本：</div>
                  <span>300万</span>
                </div>
                <div class="text">
                  <div class="lable">营业执照号：</div>
                  <span>NDJFKEIEOGJG</span>
                </div>
                <div class="text">
                  <div class="lable">认证信息：</div>
                  <span class="attest">已认证</span>
                </div>
                <div class="text">
                  <div class="lable">法人：</div>
                  <span>王富贵</span>
                </div>
              </div>
              <div class="procurement">
                <div class="title">地址</div>
                <div class="text">
                  <div class="lable">地址：</div>
                  <span>中国</span>
                </div>
                <div class="text">
                  <div class="lable">联系人：</div>
                  <span>王富贵</span>
                </div>
                <div class="text">
                  <div class="lable">联系电话：</div>
                  <span>199*****8080</span>
                </div>
              </div>
              <div class="procurement">
                <div class="title">供应商要求</div>
                <div class="text">
                  <div class="lable">所在地：</div>
                  <span>中国</span>
                </div>
                <div class="text">
                  <div class="lable">其他：</div>
                  <span>长期合作，年产值5000吨以上</span>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <el-dialog title="获取联系方式" :visible.sync="phoneLog" width="30%" center>
      <div class="contactName">王先生</div>
      <div class="contacPhone">（0575）89567858</div>
    </el-dialog>
    <el-dialog title="忽略客源" :visible.sync="ignoreLog" width="30%" center>
      <div class="ignore-text">您选择忽略此客源，请谨慎选择</div>
      <div class="ignore-radio">
        <el-radio v-model="radio" label="1">仅本次</el-radio
        ><span :style="radio == 1 ? 'color: #024A8F;opacity: 0.65;' : ''">在当前企业下次发布需求前，将不再推荐</span>
        <el-radio v-model="radio" label="2">永久忽略</el-radio
        ><span :style="radio == 2 ? 'color: #024A8F;opacity: 0.65;' : ''">当前企业的需求将不再为您推荐</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ignoreLog = false">取 消</el-button>
        <el-button type="primary" @click="ignoreLog = false" >保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import G6 from '@antv/g6';
export default {
  name: 'market',
  data() {
    return {
      value: '温州某建材公司',
      table: [
        { num: 1, name: '蓄电池', unit: '个', number: '5', remark: '请下载文档查看招标详情', attachment: '', text: '' },
        { num: 2, name: 'XXXXXXX招标项目', unit: '个', number: '5', remark: '请下载文档查看招标详情', attachment: '123121', text: 'xxx项目招标信息文档' },
      ],
      phoneLog: false,
      ignoreLog: false,
      radio: '1',
      show: false,
    };
  },
  components: {
    // basicInfo,
    // situation,
    // economicAnalysis,
    // staffing,
    // energy,
  },
  created() {},
  methods: {
    change() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 200);
    },
    mind(tab, event) {
      let data = {
        name: '上海艾瑞斯电子有限公司',
        level: '1',
        children: [
          {
            name: '蓄电池',
            level: '2',
            children: [
              {
                name: '温州某建材公司',
                level: '3',
              },
              {
                name: '温州某房地产公司',
                level: '3',
              },
            ],
          },
          {
            name: '不间断电源',
            level: '2',
            children: [
              {
                name: '某钢材贩卖点',
                level: '3',
              },
            ],
          },
          {
            name: '不断电',
            level: '2',
            children: [
              {
                level: '3',
                name: '铁路建设公司',
              },
            ],
          },
        ],
      };

      G6.registerNode(
        'tree-node',
        {
          drawShape: function drawShape(cfg, group) {
            const rect = group.addShape('rect', {
              attrs: {
                fill: cfg.level == '3' ? '#EAECF1' : cfg.level == '2' ? '#ecf5ff' : '#fff',
                stroke: '#0E459C',
                radius: [14],
                lineWidth: cfg.level == '2' ? 1 : 0,
              },
              name: 'rect-shape',
            });
            const content = cfg.name.replace(/(.{19})/g, '$1\n');
            let text = group.addShape('text', {
              attrs: {
                text: content,
                x: 0,
                y: 0,
                textAlign: 'center',
                textBaseline: 'middle',
                fill: cfg.level != '3' ? '#0E459C' : '#666666',
                fontSize: cfg.level != '1' ? 6 : 8,
                fontWeight: cfg.level != '1' ? 400 : 600,
              },
              name: 'rect-shape',
            });
            const bbox = text.getBBox();
            rect.attr({
              x: bbox.minX - 10,
              y: bbox.minY - 10,
              width: bbox.width + 20,
              height: bbox.height + 20,
            });
            return rect;
          },
        },
        'single-node'
      );

      const container = document.getElementById('container');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 479;
      const graph = new G6.TreeGraph({
        container: 'container',
        width,
        height,
        fitView: true,
        modes: {
          default: ['collapse-expand', 'drag-node', 'drag-canvas', 'zoom-canvas'],
        },
        defaultNode: {
          type: 'tree-node',
          anchorPoints: [
            [0, 0.5],
            [1, 0.5],
          ],
          linkPoints: {
            top: true,
            right: true,
            bottom: true,
            left: true,
            size: 60,
            lineWidth: 2,
          },
        },
        defaultEdge: {
          type: 'cubic-horizontal',
          style: {
            stroke: '#A3B1BF',
          },
        },
        layout: {
          type: 'compactBox',
          direction: 'LR',
          getId: function getId(d) {
            return d.id;
          },
          getHeight: function getHeight() {
            return 16;
          },
          getWidth: function getWidth() {
            return 16;
          },
          getVGap: function getVGap() {
            return 20;
          },
          getHGap: function getHGap() {
            return 80;
          },
        },
      });
      G6.Util.traverseTree(data, function (item) {
        item.id = item.name;
      });
      graph.data(data);
      graph.render();
      graph.fitView();

      graph.on('node:click', (evt) => {
        const target = evt.target;
        console.log(evt);
        console.log(evt.item.get('model'));
        if (evt.item.get('model').level == 3) {
          this.show = true;
          setTimeout(() => {
            this.show = false;
          }, 200);
          // target.attrs.fill = '#0E459C';
          graph.updateItem(evt.item, {
              style: {
                fill: '#0E459C',
              },
          });
          // graph.paint();
        }
      });
      window.onresize = function () {
        if (!graph || graph.get('destroyed')) return;
        if (!container || !container.scrollWidth || !container.scrollHeight) return;
        graph.changeSize(container.scrollWidth, container.scrollHeight);
      };
    },
  },
  mounted() {
    this.mind();
  },
  computed: {},
};
</script>
<style lang='scss' scoped>
.customersRecommend {
  padding-left: 83px;
  padding-right: 83px;
  margin: 0 auto;
}
.icon_lx {
  vertical-align: middle;
  margin-right: 5px;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
/deep/.el-table .cell {
  font-size: 10px;
  padding: 0;
  line-height: 14px;
}
/deep/.el-table td {
  border-color: #9fc0e2;
  padding: 0;
  height: 48px;
  background: #eef7ff;
}
/deep/ .el-table th {
  border-color: #9fc0e2;
  padding: 0;
  height: 48px;
  background: #dff0ff;
}
.fl-box {
  display: flex;
  flex-wrap: wrap;
}
.fl-box-b {
  display: flex;
  justify-content: space-between;
}
.recommended-box {
  background-color: #fff;
}

.company {
  width: calc(100% - 348px);
  .header {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    height: 196px;
    padding: 20px 20px 19px;
    box-sizing: border-box;

    .name {
      font-size: 16px;
      color: #333333;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .info {
      background: #f5f5f5;
      height: 116px;
      padding: 12px 15px;
      box-sizing: border-box;
      margin-right: 10px;
      width: calc((100% - 20px) / 4);
      .title {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        margin-bottom: 18px;
      }

      .text {
        font-size: 14px;
        color: #333333;
        line-height: 24px;
      }

      .lable {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
      }
    }

    .info3 {
      width: calc((100% - 20px) / 2);
      margin-right: 0;
      .title {
        margin-bottom: 6px;
      }
    }
  }
}

.attest {
  font-size: 14px;
  color: #6fc545;
  line-height: 24px;
}
.customersource {
  width: 348px;
  background: #f6faff;
  border: 1px solid rgba(14, 69, 156, 0.48);
  box-sizing: border-box;
  .title {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    margin-top: 16px;
    text-indent: 6px;
    position: relative;
    font-weight: 600;
    margin-bottom: 16px;
    &::after {
      content: '';
      width: 2px;
      height: 14px;
      background: #0e459c;
      top: 3px;
      left: 0;
      position: absolute;
    }
  }

  .header {
    background: #eef7ff;
    box-shadow: 0 2px 12px 0 rgba(14, 69, 156, 0.16);
    box-sizing: border-box;
    padding-left: 19px;
    overflow: hidden;
    .name {
      font-size: 16px;
      color: #0e459c;
      line-height: 28px;
      margin-top: 15px;
      margin-bottom: 22px;
      font-weight: 600;
    }
  }
}

.tag {
  font-size: 12px;
  color: #0e459c;
  text-align: center;
  line-height: 24px;
  background-image: linear-gradient(90deg, #90b9fb 0%, #e9f4ff 100%);
  border-radius: 4px;
  height: 24px;
  padding: 0 11px;
  margin-right: 16px;
  margin-bottom: 12px;
}

.select-box {
  height: 80px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.p020 {
  padding: 0 20px;
}

.procurement {
  .lable {
    font-size: 12px;
    color: #666666;
    line-height: 20px;
    width: 84px;
    display: inline-block;
  }

  .text {
    font-size: 12px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .text .index {
    font-size: 14px;
    color: #0e459c;
    font-weight: 600;
  }
}

.dynamic .text {
  margin-top: -3px;
  font-size: 12px;
  color: #333333;
  line-height: 26px;
}
.contactName {
  font-size: 14px;
  color: #606266;
  text-align: center;
  margin-bottom: 21px;
}
.contacPhone {
  font-size: 24px;
  color: #024a8f;
  text-align: center;
  line-height: 18px;
  margin-bottom: 10px;
}
.ignore-text {
  font-size: 14px;
  color: #606266;
  margin-bottom: 24px;
}
.ignore-radio {
  span {
    font-size: 14px;
    color: #cccccc;
    line-height: 18px;
  }
  /deep/.el-radio__label {
    margin-right: 0;
    font-size: 14px;
    color: #666660;
    line-height: 18px;
  }
  /deep/.is-checked .el-radio__label {
    color: #024a8f;
  }
}
/deep/.el-dialog__footer {
  text-align: right;
}
</style>

